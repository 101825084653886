.App {
  text-align: left;
}
.GenerateComposite {
  width: 45%;
  padding: 10px;
  margin: 10px;
  border: solid 1px;
  float: left;
}
